<template>
    <div class="main-container" style="padding-right:0;padding-left:0" oncontextmenu="return false;">
        <section class="bg-gray pt-4">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-xl-8 col-md-7" style="padding-right: 10px;padding-left: 10px;" v-for="publish in content" :key="publish.id">
                        <div class="row">
                            <div class="col-12" >
                                <video  class="plyr" autoplay style="margin-right: 0px;width: 100%;" controls loop controlsList="nodownload">
                                    <source :src="publish.source" type="Video/mp4" size="720">
                                </video>
                            </div>
                        </div>
                        <div class="row" style=" padding: 5px 5px; margin-bottom: 8px;">
                            <div class="col-12">
                                <div class="comments__item">
									<div class="comments__autor">
										<!-- <img class="comments__avatar" src="assets/img/avatar.svg" style="width: 50px;height: 50px;" alt=""> -->
										<span class="comments__name arabicKufi" style="color: #046162;margin-right: 5px;"><h4>{{ publish.title_ar }} - {{ publish.year.substring(0,4) }}</h4></span>
										<!-- <span class="comments__time" style="color: #046162;margin-right: 5px;" ><h6></h6></span> -->
									</div>
								</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-md-5" style="padding-right: 0;padding-left: 0;">
                        <div id="viewtube_related_videos-2"  class="widget widget-video widget_viewtube_related_videos">
                            <ul class="sidebar-related-video">
                                <li style="box-shadow: 0px 2px 1px #7d76766b" v-for="publish in CategoryContent" :key="publish.id">
                                    <div class="related-video-thumb pointer">
                                        <a @click="GoToContent(publish)">
                                            <img v-lazy="publish.carsoul_img" alt="Image"> 
                                        </a>
                                    </div>
                                    <div class="related-video-content pointer">
                                        <p>
                                            <a @click="GoToContent(publish)">{{publish.title_ar}}</a>
                                        </p>
                                        <ul>
                                            <li>
                                                <a class="author" @click="GoToContent(publish)">
                                                    أ / {{publish.cp_name}} </a>
                                            </li>
                                            <li>
                                                <div class="d-flex video-meta-bottom" dir="ltr">
                                                     Mar {{publish.year}}  </div>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { HTTP } from '@/Api/http-common';
import { ref } from '@vue/reactivity';
import { useRouter } from "vue-router"
import { useCookie } from 'vue-cookie-next'
import  config  from '@/Api/config';
export default {
    name:'AppContent',
    props :{
        content_id:String,
    },
   async setup(props) {
    const router = useRouter();
    const cookie = useCookie();
    const CategoryContent = ref([]);
    const content = ref([]);
    
    try {
      await HTTP.get(`ContentById.php?CID=${props.content_id}`, {
          headers: {
            'Authorization': config.authHeader,
          },
      }).then((res) => {
        content.value = res.data.Content; 
      });
    } catch (err) {
      console.log(err);
    }

    try {
      await HTTP.get(`GetContentByCategory.php?LIMIT=50&OFFSET=0&CID=${props.content_id}`, {
          headers: {
            'Authorization': config.authHeader,
          },
      }).then((res) => {
          CategoryContent.value = res.data.Content; 
      });
    } catch (err) {
      console.log(err);
    }

    const GoToContent = (publish) => {
       router.push({ name: "Contents", params: { content_id: publish.id } });
    };
  
    return { CategoryContent ,content, GoToContent};
    
  },

}
</script>

<style scoped>
.pointer {
  cursor: pointer;
}

/* .iframe-scale {
    width: 100vw;
height: 88vh;
}
@media(max-width:786px){
    .iframe-scale {
    height: 45vh;
}
} */

.title {
    font-family: 'Zain_Regular', sans-serif;
    font-weight: 500;
    font-size: 18px;
    color: #fff;
    margin-top: 10px;
    margin-bottom: 5px;
    transition: 0.5s;
    display: block;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    text-align: start;
    overflow: hidden;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    word-wrap: break-word;
    background-color: #042f72;
background-image: linear-gradient(62deg, #047072 0%, #009ac9 100%);
    padding: 8px 5px 5px 5px;
    border-radius: 12px;


}
.catalog2 {
    position: relative;
    padding: 5px 0 25px;
    padding-top: calc(50vh - 90%);
    padding-right: 0px;
    padding-bottom: 25px;
    padding-left: 0px;
    overflow: hidden;
    padding-bottom: calc(50vh - 60%);
}
.catalog__paginator-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 40px auto 0;
    width: 100%;
    height: 50px;
    border-radius: 16px;
    background-color: #151f30;
    background-color: #047072;
    background-image: linear-gradient(62deg, #047072 0%, #00c9c9 100%);
}

.inner-plyr {
  margin-right: 0;
  width: 100%;
}

.view {
  margin-left: 0;
  padding: 0 !important;
  z-index: 98;
  top: 0px;
}


    @media (min-width: 1200px) {
       .plyr {
           margin-right: 22px;
        }  
    }


@media (max-width: 768px) {
  .inner-plyr, .view {
  margin-right: 0;
  width: 100%;
}

.view {
  margin-left: 0;
padding: 0 !important;
z-index: 98;
top: 0px;
margin-right: 0;
}

.plyr {
  margin-top: 50px;
}
}
@media (max-width: 768px) and (min-width:412px) {


.view {

margin-right: -4%;
}

}
@media (max-width: 400px) {
  .inner-plyr, .view {
  margin-right: 0;
  width: 100%;
}

.view {
  margin-left: 0;
padding: 0 !important;
z-index: 98;
top: 0px;
margin-right: -4%;
}

.plyr {
  margin-top: 50px !important;
  margin-right: -2px !important;
}
}
@media (max-width: 900px ) and (min-width: 768px) {
  

.plyr {
  margin-top: 90px !important;
}
.view {

margin-right: 0;
}
}

.plyr {
  font-family: 'Zain_Regular', sans-serif;
  font-weight: 400;
  border-radius: 0px;
  margin-top: 22px;
}

.card__cover::before {
  content: '';
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.8) 75%, rgba(0, 0, 0, 0.9) 100%);
  opacity: 0.1;
  transition: 0.5s;
}
</style>